import React, { useState } from "react";
import { Section } from "./Section";
import { RichText } from "./RichText";
import { WaveBackground } from "./WaveBackground";
import loadable from "@loadable/component";
import BlueWave from "../images/blue-wave.png";
import { Buttons } from "./Common";

const DashboardChartsAnimation = loadable(() =>
  import("./lottie/DashboardChartsAnimation")
);

export const SectionOne = ({ title, description, buttons }) => {
  const [indicatorRef, setIndicatorRef] = useState(null);
  const getOffsetTop = () => indicatorRef.offsetTop;

  return (
    <>
      {indicatorRef && (
        <WaveBackground getOffsetTop={getOffsetTop} image={BlueWave} />
      )}
      <div className="position-relative bg-md-blue-gradient">
        <Section width={12} className="py-5rem">
          <div className="col-12 col-lg-6 d-flex flex-column">
            <h2
              className="fs-1_75 fs-xl-2 fs-xxl-2_5 extra-bold pb-4 c-black w-md-75"
              data-aos="fade-up"
              data-aos-delay="50"
            >
              {title}
            </h2>
            <div
              className="pb-3rem w-auto w-md-75"
              data-aos="fade-up"
              data-aos-delay="100"
            >
              <RichText text={description.raw} />
            </div>

            <div
              ref={(el) => setIndicatorRef(el)}
              data-aos="fade-up"
              data-aos-delay="150"
            >
              {buttons && <Buttons buttons={buttons} />}
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <DashboardChartsAnimation />
          </div>
        </Section>
      </div>
      <div className="h-0 h-lg-5rem" />
    </>
  );
};
