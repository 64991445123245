import "../scss/app.scss";
import React from "react";
import { graphql } from "gatsby";
import { Navbar } from "../component/Navbar";
import { SectionOne } from "../component/SectionOne";
import { Contact } from "../component/Contact";
import { Footer } from "../component/Footer";
import { SectionTextImage } from "../component/SectionTextImage";
import { SectionTextAnimation } from "../component/SectionTextAnimation";
import { SectionText } from "../component/SectionText";
import { Pictograms } from "../component/Pictograms";
import { Feedbacks } from "../component/Feedbacks";
import { SectionImage } from "../component/SectionImage";
import { Buttons } from "../component/Buttons";
import { Identifications } from "../component/Identifications";
import { Spacer } from "../component/Spacer";
import { usePageInit } from "../component/hook/usePageInit";
import { Cookies } from "../component/Cookies";
import AppHelmet from "../component/AppHelmet";
import { Partners } from "../component/Partners";

const components = {
  ContentfulNavbar: Navbar,
  ContentfulSectionOne: SectionOne,
  ContentfulSectionTextImage: SectionTextImage,
  ContentfulSectionTextAnimation: SectionTextAnimation,
  ContentfulSectionText: SectionText,
  ContentfulContact: Contact,
  ContentfulFooter: Footer,
  ContentfulPictograms: Pictograms,
  ContentfulPartners: Partners,
  ContentfulFeedbacks: Feedbacks,
  ContentfulSectionImage: SectionImage,
  ContentfulSectionButtons: Buttons,
  ContentfulIdentifications: Identifications,
  ContentfulSpacer: Spacer,
};

function Index({ data }) {
  const { popup } = usePageInit();

  const {
    contentfulPage: { sections },
  } = data;

  return (
    <main>
      <AppHelmet />
      {popup}
      {sections.map(({ id, __typename, ...props }) => {
        const SectionTag = components[__typename];

        return SectionTag && <SectionTag {...props} key={id} />;
      })}
      <Cookies />
    </main>
  );
}

export default Index;

export const sectionOneQuery = graphql`
  query mainQuery {
    contentfulPage(id: { eq: "7fb60a38-27a2-51a5-ab55-fb500dbe16a3" }) {
      title
      id
      sections {
        ... on ContentfulNavbar {
          id
          __typename
          logo {
            fluid {
              src
            }
          }
          navigationItems {
            items {
              id
              url
              label
              type
              columnNumber
              dropdownItems {
                id
                url
                label
                description
                text {
                  raw
                }
                image {
                  fluid {
                    src
                  }
                }
              }
            }
          }
          companyInformation {
            contactEmail
            copyrights {
              raw
            }
            socialMedia {
              alt
              id
              url
              icon {
                fluid {
                  src
                }
              }
            }
          }
        }
        ... on ContentfulFooter {
          id
          __typename
          logo {
            fluid {
              src
            }
          }
          copyrights {
            raw
          }
          termsAndConditions {
            file {
              url
            }
          }
          contactEmail
          social {
            url
            icon {
              fluid {
                src
              }
            }
            alt
          }
          navigation {
            id
            url
            label
          }
        }
        ... on ContentfulContact {
          id
          __typename
          columnLeft {
            description {
              raw
            }
            title
            successMessage {
              raw
            }
          }
          columnRight {
            description {
              raw
            }
            description2 {
              raw
            }
            title
            phone
            email
          }
        }
        ... on ContentfulSectionOne {
          id
          __typename
          title
          description {
            raw
          }
          image {
            title
            id
            description
            fluid {
              src
            }
          }
          buttons {
            backgroundColor
            borderColor
            borderWidth
            url
            text
            textColor
            outline
          }
        }
        ... on ContentfulSectionTextImage {
          id
          __typename
          title
          description {
            raw
          }
          iconClass
          image {
            fluid {
              src
            }
          }
          imagePosition
          imageWidth
          imageAlign
          textColor
          backgroundColor
          buttons {
            backgroundColor
            borderColor
            borderWidth
            url
            text
            textColor
            outline
          }
          smallText {
            raw
          }
          spaceBottom
          spaceTop
          aosOffset
          isPromotionalVideo
        }
        ... on ContentfulSectionTextAnimation {
          id
          __typename
          title
          description {
            raw
          }
          animation
          animationPosition
          animationAlign
          backgroundColor
          spaceBottom
          spaceTop
        }
        ... on ContentfulPictograms {
          id
          __typename
          backgroundColor
          titleAndTextColumns {
            id
            title
            titleAlign
            text {
              raw
            }
            textFontSize
            textAlign
            image {
              fluid {
                src
              }
            }
          }
          button {
            backgroundColor
            borderColor
            borderWidth
            textColor
            url
            text
            outline
          }
          pictogramsAlign
          pictogramTitleUppercase
          spaceBottom
          spaceTop
        }
        ... on ContentfulSectionImage {
          id
          __typename
          backgroundColor
          image {
            file {
              url
            }
          }
          spaceBottom
          spaceTop
          animationType
        }
        ... on ContentfulSectionText {
          id
          text {
            raw
          }
          backgroundColor
          spaceBottom
          spaceTop
          width
        }
        ... on ContentfulSectionButtons {
          id
          __typename
          buttons {
            text
            url
            backgroundColor
            borderColor
            borderWidth
            textColor
            outline
          }
          backgroundColor
          spaceBottom
          spaceTop
          alignButtons
        }
        ... on ContentfulFeedbacks {
          id
          __typename
          title
          items {
            id
            person
            description
            content {
              raw
            }
            image {
              fluid {
                src
              }
            }
          }
          spaceBottom
          spaceTop
        }
        ... on ContentfulPartners {
          id
          items {
            image {
              fluid {
                src
              }
            }
          }
          title
          spaceBottom
          spaceTop
          width
          description {
            raw
          }
          showSlider
          itemsPerSlide
        }
        ... on ContentfulIdentifications {
          id
          __typename
          spaceBottom
          spaceTop
          identifications {
            id
            title
            image {
              fluid {
                src
              }
            }
          }
          description {
            raw
          }
          backgroundColor
          textColor
        }
        ... on ContentfulSpacer {
          id
          __typename
          size
          backgroundColor
        }
      }
    }
  }
`;
